import React from 'react';
import './assets/css/tailwind.css';
import './assets/css/App.css';
import './assets/css/home.css'
import Home from "./pages/Home";

function App() {
  return (
    <div className="App">
        <Home />
    </div>
  );
}

export default App;
