import React, {useEffect, useRef, useState} from 'react';
import './../assets/css/videoplayer.css'

export interface VideoPlayerProps {
    source: string,
    className?: string
}

const VideoPlayerComponent = ({ source, className }: VideoPlayerProps) => {
    const muteRef = useRef<HTMLButtonElement>(null)
    const [isMuted, setIsMuted] = useState(true);
    const timerRef = useRef<NodeJS.Timeout>();

    const startTimer = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(hideMute, 2000);
    }

    const hideMute = () => {
        if (!muteRef.current) return;
        muteRef.current.classList.add("opacity-0");
        muteRef.current.classList.add("pointer-events-none");
    }

    const showMute = () => {
        clearTimeout(timerRef.current);
        if (!muteRef.current) return;
        muteRef.current.classList.remove("opacity-0");
        muteRef.current.classList.remove("pointer-events-none");
    }

    const toggleMute = () => {
        setIsMuted(!isMuted);
    }

    useEffect(() => {
        if (!isMuted) {
            startTimer();
        } else {
            showMute();
        }
    }, [isMuted])

    return (
        <figure className={(className ? className : '') + " relative"}>
            <button className={"absolute justify-center items-center w-full h-full z-10 flex transition-all duration-300"}
                    ref={muteRef}
                    onClick={toggleMute}>
                {
                    isMuted && (
                        <img src={"/assets/img/volume-on.svg"} className={"w-[80px] opacity-80"} />
                    )
                }

                {
                    !isMuted && (
                        <img src={"/assets/img/volume-mute.svg"} className={"w-[80px] opacity-80"} />
                    )
                }

            </button>

            <video preload="metadata" muted={isMuted} loop={true} playsInline={true} autoPlay={true}
                   onClick={toggleMute}
                className={"relative min-w-full min-h-full z-[-1]"}>
                <source
                    src={source}
                    type="video/mp4" />
            </video>
        </figure>
    );
}

export default VideoPlayerComponent;
