import React, {createRef, useEffect} from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import VideoPlayer from "../components/VideoPlayer";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
    const bgRef = createRef<SVGSVGElement>();
    const visibleFrameRef = createRef<SVGRectElement>();
    const centerFrameRef = createRef<SVGRectElement>();
    const homeHeaderRef = createRef<HTMLDivElement>();
    const backgroundImageRef = createRef<SVGImageElement>();
    const nftImagesRef = createRef<HTMLDivElement>();

    const getOffsetRect = (el: HTMLElement | SVGRectElement) => {
        let rect   = el.getBoundingClientRect();

        // add window scroll position to get the offset position
        let left   = rect.left   + window.scrollX;
        let top    = rect.top    + window.scrollY;
        let right  = rect.right  + window.scrollX;
        let bottom = rect.bottom + window.scrollY;

        // polyfill missing 'x' and 'y' rect properties not returned
        // from getBoundingClientRect() by older browsers
        let x = rect.x === undefined ? left : rect.x + window.scrollX;
        let y = rect.y === undefined ? top : rect.y + window.scrollY;

        // width and height are the same
        let width  = rect.width;
        let height = rect.height;

        return { left, top, right, bottom, x, y, width, height };
    }

    useEffect(() => {
        function centerObject() : {offsetX : number, offsetY: number} {
            if (!bgRef.current || !centerFrameRef.current || !visibleFrameRef.current) return { offsetX: 0, offsetY: 0};

            const {
                innerWidth,
                innerHeight
            } = window;

            const centerX = innerWidth/2;
            const centerY = innerHeight/2;

            const bgElement = bgRef.current;
            const {width, height, x, y} = getOffsetRect(centerFrameRef.current);

            const offsetX = centerX - (x + (width/2));
            let offsetY = centerY - (y + (height/2));

            bgElement.style.transform = `translate(${offsetX}px, ${offsetY}px)`

            return {offsetX, offsetY};
        }
        function onResize() {
            if (!bgRef.current || !centerFrameRef.current || !visibleFrameRef.current || !backgroundImageRef.current || !homeHeaderRef.current) return;

            const bgStyle = bgRef.current.style;

            bgStyle.transformOrigin = '0 0'
            bgStyle.scale = '1'
            bgStyle.transform = '';

            const {offsetX: centerOffsetX, offsetY: centerOffsetY} = centerObject();

            const {innerHeight} = window;
            const {x, y, width, height} = getOffsetRect(centerFrameRef.current);

            const centerX = x + (width/2)
            const centerY = y + (height/2)

            const scale = Math.min(1, Math.max(centerY/innerHeight, 0.70));
            bgStyle.transformOrigin = `${centerX}px ${centerY}px`;
            bgStyle.scale = `${scale}`;

            const {y: visibleFrameY} = getOffsetRect(visibleFrameRef.current);

            if (visibleFrameY < 0) {
                bgStyle.transform = `translate(${centerOffsetX}px, ${centerOffsetY-visibleFrameY}px)`
                return true;
            }
        }

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [backgroundImageRef, bgRef, centerFrameRef, homeHeaderRef, visibleFrameRef])

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: '.home-header--disposable-content',
                endTrigger: '.home-spacer',
                pin: true,
                start: 'top top',
                markers: false,
                scrub: true
            }
        }).to('.st-zoom-in', {
            scale: 1.5
        })
        .to('.st-fade', {
            opacity: 0,
            rotate: 20,
            transformOrigin: 'center'
        }, 0.5)
        .to('.st-fade-2', {
            opacity: 0,
        }, 0.7)
        .to('.st-move', {
            scale: 0.6,
            left: 0,
            transformOrigin: 'center',
        }, 0.9)

        gsap.timeline({
            scrollTrigger: {
                trigger: '.home-header--sticky',
                endTrigger: '.title',
                pin: true,
                start: 'top top',
                markers: false,
                scrub: true
            }
        })

        return () => {
            gsap.killTweensOf('.st-zoom-in');
            gsap.killTweensOf('.st-fade');
            gsap.killTweensOf('.st-fade-2');
            gsap.killTweensOf('.st-move');
            gsap.killTweensOf('.home-header--sticky');
        }
    }, [])

    useEffect(() => {
        if(!nftImagesRef.current) return;

        const nftImages = nftImagesRef.current.querySelectorAll('.nft-image');

        gsap.fromTo(nftImages, {
            x: 100,
            opacity: 0
        }, {
            x: 0,
            opacity: 1,
            stagger: {
                each: 0.1,
                from: 'start'
            },
            scrollTrigger: {
                trigger: '.nft-images',
                start: 'top center',
                end: 'bottom center',
                scrub: true,
            }
        })

        return () => {
            gsap.killTweensOf(nftImages);
        }

    }, [nftImagesRef])

    return (
        <>
            <header className="home-header overflow-hidden relative">
                <div className={"home-header--disposable-content absolute w-screen overflow-hidden"}>
                <svg width="99vw" height="100vh" viewBox="0 0 2560 2396">
                    <g className={"st-zoom-in"} style={{ transformOrigin: '1250px' }}>
                        <image x="0" y="0" width="6076px" height="2396" href={"/assets/img/v3/dollar bill - full width-min.png"} className={'st-fade-2'} />
                        <image x="0" y="0" width="2560" height="2396" href={"/assets/img/v3/3.png"} className={'st-fade'}/>
                    </g>
                </svg>
                </div>
                <div className={"home-header--sticky z-40 overflow-hidden w-screen"}>
                    <svg width="99vw" height="100vh" viewBox="0 0 2560 2396">
                        <g className={"st-zoom-in st-move origin-center"}>
                            <image x="0" y="0" width="2560" height="2396" href={"/assets/img/v3/1.png"} className={" anim-float"} />
                            <image x="0" y="0" width="2560" height="2396" href={"/assets/img/v3/2.png"} className={" anim-float anim-float--6s "} />
                        </g>
                    </svg>
                </div>
            </header>
            <div className={"w-full h-[100vh] home-spacer absolute top-0 left-0"} />
            <main className={"home-content w-full mt-20"}>
                <div className={"w-full flex items-center flex-col"}>
                    <div className={"w-3/4 md:w-2/3 flex justify-center"}>
                        <div className={"w-fit flex flex-col items-center"}>
                            <img src={"/assets/img/title.png"} className={"title"} alt='page-title' />
                            <div className={"md:w-2/3 text-primary mt-20 sm:w-3/3"}>
                                <p className='max-w-prose mx-auto'>
                                    A beacon of light on the horizon for the new financial system, turning the old system upside down.
                                    By weathering through the SEC case and through a difficult and complicated process, finally, the locks are off, and the path forward for XRP and ‘XRP Proof of Currency’ is there.
                                    The financial system is in breach of the biggest change in history.
                                    Know what you hold.
                                    The inverted pyramid is a symbol of these changes, a symbol of the new ways of the future.
                                    The upside-down pyramid stands for changing the financial system and giving us, the people, also a chance to be one of the first, for one of the best investments in history.
                                    Sometimes it felt like a trip, we were being fooled, and it was difficult to keep a clear mind.
                                    Always remember what you hold.
                                    Through storms, and with ups and downs, we weathered our way through and remained strong, because we know the fundamentals.
                                    XRP Proof of Currency is a set of 5 NFTs, each of them tells its own story on XRP within the new financial system
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='mt-16 flex gap-3 justify-center md:w-2/3 flex-wrap'>
                        <a
                            href="/assets/pdf/XRP-whitepaper-low.pdf"
                            className='text-primary border-primary border-2 px-4 py-2 rounded cursor-pointer'
                            target='_blank'
                        >
                            Read the NFT whitepaper
                        </a>
                        <a href="#roadmap" className='text-primary border-primary border-2 px-4 py-2 rounded cursor-pointer'>
                            Check the NFT Roadmap
                        </a>
                    </div>
                    <div className='mt-8 flex gap-3 justify-center items-baseline md:w-2/3 flex-wrap'>
                        <a href={"https://twitter.com/xrppoc"} target={"_blank"} rel={"nofollow"}>
                            <img src={"/assets/img/twitter.svg"} className={"w-[30px]"} alt={"twitter"} />
                        </a>

                        <a href={"https://www.instagram.com/studiotimdevries/"} target={"_blank"} rel={"nofollow"}>
                            <img src={"/assets/img/instagram.svg"} className={"w-[30px]"} alt={"instagram"} />
                        </a>
                    </div>

                    <div className='mt-20 flex gap-3 justify-center md:w-2/3 flex-wrap'>
                    <img src={"/assets/img/theone.png"} />
                    </div>

                   <NFTPreviewContainer content={NFTContent} />

                    <div className={"md:-3/6 flex flex-col justify-center mt-20 space-y-10"} id='roadmap'>
                        {phaseContent.map((phase, index) => (
                            <PhaseSection key={index} phase={phase} index={index} />
                        ))}
                    </div>
                </div>


                <div className={"w-full grid grid-cols-10 mt-20"}>
                    {[...Array(20)].map((_x,i) => (
                        <img
                            key={i}
                            src={"/assets/img/examples/1.png"}
                            className={'max-h-fit hue-rotate'}
                            alt='example-nft'
                            style={{animationDelay: `${i * 0.5}s`}}
                        />
                    ))}
                </div>
            </main>
        </>
    )
}

const NFTContent = [
    {
        title: 'Turning locks pyramid',
        description: 'A symbol of progress, representing the new financial system being put in place.',
        image: '/assets/img/nfts/01.mp4'
    },
    {
        title: 'Hovering inverted pyramid',
        description: "A symbol of resilience and strength, representing the new financial system's ability to guide us through the storms.",
        image: '/assets/img/nfts/02_v2.mp4'
    },
    {
        title: 'Trip pyramid',
        description: 'A symbol of the journey through the years, representing the growth and evolution of XRP and the crypto space.',
        image: '/assets/img/nfts/03.mp4'
    },
    {
        title: 'Beacon of light',
        description: 'A symbol of hope, representing a guiding light towards the right path.',
        image: '/assets/img/nfts/04.mp4'
    },
    {
        title: 'Upside-down pyramid',
        description: "The pyramid of the old financial system turns upside down, representing the new financial system's revolutionary change.",
        image: '/assets/img/nfts/05.mp4'
    }
]

const NFTPreviewContainer = (props: {content: typeof NFTContent}) => {
    return (
        <div className='w-2/3 max-w-[1100px] mx-auto mt-32'>
            {props.content.map((nft, index) => (
                <NFTPreview key={index} nft={nft} index={index}/>
            ))}
        </div>
    )
}


const NFTPreview = (props: {nft: typeof NFTContent[0], index: number}) => {
    const isEven = props.index % 2 === 0;
    const { title, description, image } = props.nft;

    const previewRef = createRef<HTMLDivElement>();

    useEffect(() => {
        const preview = previewRef.current;
        if (!preview) return;

        preview.style.animationDelay = `${props.index * 0.5}s`;

        gsap.from(preview, {
            opacity: 0,
            y: "100px",
            duration: 2,
            scrollTrigger: {
            trigger: preview,
                start: 'top top-=300px',
                end: 'bottom top-=500px',
                toggleActions: 'play none none reverse',
                markers: false,
            },
        })


    }, [previewRef, props.index])

    return (
        <div ref={previewRef} className={`
            flex flex-row flex-col items-center gap-6 mb-24
            md:flex-row md:gap-12 md:mb-12
            ${isEven ? 'md:flex-row-reverse' : ''} 
        `}>
            <div className={`
                flex flex-col items-center text-center
                sm:items-center sm:text-center 
                ${isEven ? 'order-1 md:items-end md:text-right' : 'order-2 md:items-start md:text-left'} flex-3
            `}>
                <h2 className='text-primary font-bold mb-2 text-xl md:text-2xl'>{title}</h2>
                <p className='text-primary max-w-prose w-[70%] md:text-lg mb-4'>{description}</p>
                <a className='text-primary border-primary border-2 px-4 py-2 rounded cursor-pointer'
                   href={"https://nft.onxrp.com/launchpad/details/xrp-proof-of-currency/"}
                   target={"_blank"}>Mint on OnXRP</a>
            </div>
            <VideoPlayer
                source={image}
                className={"flex-1 max-w-[280px] object-contain rounded anim-float"}
            />
        </div>
    )
}



const PhaseSection = (props: {phase: Phase, index: number}) => {
    const { image, title, steps } = props.phase;

    const rotateToMouse = (event: Event, target: HTMLDivElement) => {
        const e = event as MouseEvent;
        const rect = target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const width = target.offsetWidth;
        const height = target.offsetHeight;
        const centerX = width / 2;
        const centerY = height / 2;
        const deltaX = x - centerX;
        const deltaY = y - centerY;
        const percentX = deltaX / (width / 2);
        const percentY = deltaY / (height / 2);
        const deg = 4;
        const offsetX = percentX * deg;
        const offsetY = percentY * deg;
        target.style.transform = `
            perspective(1000px) rotateX(${-offsetY}deg) rotateY(${offsetX}deg)
            scale3d(1.03, 1.03, 1.03)
        `;

    }

    const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.target as HTMLDivElement;
        e.target.addEventListener('mousemove', (event) => rotateToMouse(event, target));
    }

    const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.target as HTMLDivElement;
        target.style.transform = '';
        e.target.removeEventListener('mousemove', (event) => rotateToMouse(event, target));
    }

    return (
        <div className='mt-4'>
            <img src={image} className={'inline'} alt='phase-title' />

            <h2 className='text-primary text-2xl mb-4'>{title}</h2>

            <div className='flex px-6 pb-5 gap-6 justify-center flex-wrap' style={{transformStyle: "preserve-3d"}}>
                {steps.map((step, i) => (
                    <div
                        key={i}
                        className='step-card'
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <div
                            className={`pointer-events-none relative p-3 py-8 flex flex-col items-center text-center h-[275px] max-w-[300px] rounded justify-center border-opacity-50 rotating-border ${!step.done ? 'opacity-75' : ''}`}
                            style={{
                                animationDelay: `${i * 0.7}s`,
                            }}
                        >
                            <span className='absolute top-4 right-0 text-2xl transform translate-x-1/2'>
                                {step.done ? <img src='/assets/img/check.png' alt='check' width={30} height={30} /> : null}
                            </span>
                            <b className='text-primary text-lg mb-3'>{step.title}</b>
                            <p className='text-primary'>{step.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Home;

const phaseContent = [
    {
        image: '/assets/img/one.png',
        title: 'Launch and Early Sales',
        steps: [
            {
                title: 'Concept and Design',
                text: 'This step involves conceptualizing the project and designing the NFTs based on the five interpretations of the great seal of the new pyramid. This will also include the creation of the physical and digital art.',
                done: true
            },
            {
                title: 'Launch and Initial Sales (25%)',
                text: 'The XRP Proof of Currency NFT project will be launched and 20 upside-down pyramid prints will be gifted to randomly selected buyers. As a bonus, one of these buyers will be chosen at random to receive a giveaway.',
                done: false
            }
        ]
    },
    {
        image: '/assets/img/two.png',
        title: 'Mid-Range Sales',
        steps: [
            {
                title: '50% Sales Milestone',
                text: 'Once 50% of the NFTs have been sold, 5 silver XRP pyramid coins will be given away to randomly selected buyers.'
            },
            {
                title: '75% Sales Milestone',
                text: 'When 75% of the NFTs have been sold, a physical XRP upside-down pyramid will be given away to a randomly selected buyer.'
            }
        ]
    },
    {
        image: '/assets/img/three.png',
        title: 'Final Sales and Closing',
        steps: [
            {
                title: 'Closing and 100% Sales Milestone',
                text: 'Once all 1905 XRP Proof of Currency NFTs have been sold, a final giveaway will be held for one golden XRP pyramid coin.'
            }
        ]
    },
    {
        image: '/assets/img/four.png',
        title: 'Future Milestones and Growth',
        steps: [
            {
                title: 'Future Milestones and Growth',
                text: 'With new milestone moments for XRP in the future, there will be new physical and digital art created for the XRP Proof of Currency NFT project. This will continue to celebrate the achievements of XRP and the crypto space as a whole.'
            }
        ]
    }
]

type Phase = {
    image: string;
    title: string;
    steps: {
        title: string;
        text: string;
        done?: boolean;
    }[];
}
